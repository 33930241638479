<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="container">
                    <el-form label-width="110px"
                             size="small"
                             :model="form"
                             :rules="rules"
                             :disabled="!isEdit"
                             ref="form">
                        <section>
                            <el-row>
                                <el-col :span="16">
                                    <el-form-item label="发布类型：">
                                        <el-radio-group v-model="form.trainingType" :disabled="disabledTrainingType">
                                            <el-radio :label="vm.value" v-for="vm in trainingTypeOptions"
                                                      :key="vm.value"
                                                      :disabled="vm.value==='3'&&hasPreJobTraining">
                                                {{ vm.label }}
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="宣导主题：" prop="trainingName">
                                        <el-input placeholder="请输入标题，最多可填入20个中文字"
                                                  v-model="form.trainingName" :maxlength="20" show-word-limit/>
                                    </el-form-item>
                                    <el-form-item label="宣导内容：" prop="trainingLessonDtos">
                                        <el-button type="primary" plain @click="showDialog(0)">
                                            请课件库中选择课件
                                        </el-button>
                                        <el-table empty-text="请课件库中选择课件" class="topic-table" border
                                                  :data="form.trainingLessonDtos">
                                            <el-table-column label="序号" type="index" align="center"
                                                             width="60px"></el-table-column>
                                            <el-table-column label="课件类型" prop="lessonTitle"
                                                             width="120px"></el-table-column>
                                            <el-table-column label="课件主题" prop="lessonName"></el-table-column>
                                            <el-table-column label="课件封面" prop="lessonCoverUrl" align="center"
                                                             width="80px">
                                                <template slot-scope="scope">
                                                    <el-image :src="scope.row.lessonCoverUrl"
                                                              style="width: 40px;height: 40px"
                                                              :preview-src-list="[scope.row.lessonCoverUrl]"></el-image>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="操作" align="center" width="80px">
                                                <template slot-scope="scope">
                                                    <el-popconfirm title="确定删除？"
                                                                   @confirm="handleDelItems(0,scope.$index)">
                                                        <el-link slot="reference" type="danger" :underline="false">删除
                                                        </el-link>
                                                    </el-popconfirm>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" align="middle">
                                    <div class="preview-box lesson">
                                        <div class="empty" v-if="!form.trainingLessonDtos.length">
                                            <p class="color-gary  font_s16"
                                               style="padding-top: 120px;text-align: center">
                                                内容预览
                                            </p>
                                        </div>
                                        <div class="content" v-else>
                                            <div class="hd">
                                                <p class="title">{{ form.trainingName }}</p>
                                                <p>
                                                    <img src="@/www/assets/images/driverLearning/jiangbei.png" alt="">
                                                    <span>学习目标：阅读学习资料</span>
                                                </p>
                                                <p>
                                                    <img src="@/www/assets/images/driverLearning/time-circle-fill.png"
                                                         alt="">
                                                    <span>完成时间：{{
                                                            form.hasLastTime ? (form.lastTime ? (dayjs(form.lastTime).format('YYYY-MM-DD') + '前完成') : '') : '需立即完成'
                                                        }}</span>
                                                </p>
                                            </div>
                                            <div class="title">
                                                学习资料：
                                            </div>
                                            <ul>
                                                <li v-for="vm in form.trainingLessonDtos" :key="vm.id">
                                                    <img class="cover" :src="vm.lessonCoverUrl" alt=""/>
                                                    <div
                                                        style="display: flex;flex-direction: column;justify-content: space-between">
                                                        <p>{{ vm.lessonName }}</p>
                                                        <p>
                                                            <img
                                                                src="@/www/assets/images/driverLearning/weitongguo.png"
                                                                alt="">
                                                            <span class="color-gary" style="font-size: 10px">未学</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </section>
                        <section v-if="['2','3'].includes(form.trainingType)">
                            <el-row>
                                <el-col :span="16">
                                    <el-form-item label="答题：" style="width: 100%" prop="trainingQuestionDtos">
                                        <el-button type="primary" plain @click="showDialog(1)">
                                            从题库中选择题目
                                        </el-button>
                                        <el-table empty-text="请去题库中选择题目" class="topic-table" border
                                                  :data="form.trainingQuestionDtos">
                                            <el-table-column label="序号" type="index" width="60px"
                                                             align="center"></el-table-column>
                                            <el-table-column label="题目" prop="questionTitle">
                                                <template slot-scope="scope">
                                                    <el-image v-if="scope.row.questionPhotoUrl"
                                                              :src="scope.row.questionPhotoUrl"
                                                              style="width: 80px;"
                                                              :preview-src-list="[scope.row.questionPhotoUrl]"></el-image>
                                                    <p>{{ scope.row.questionTitle }}</p>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="答案" prop="questionInfo">
                                                <template slot-scope="scope">
                                                    <p v-for="(vm,index) in scope.row.questionInfo" :key="vm.id"
                                                       :class="vm.checked?'color-success':''">
                                                        {{ letters[index] }} {{ vm.content }}
                                                    </p>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="操作" width="80px" align="center">
                                                <template slot-scope="scope">
                                                    <el-popconfirm title="确定删除？"
                                                                   @confirm="handleDelItems(1,scope.$index)">
                                                        <el-link slot="reference" type="danger" :underline="false">删除
                                                        </el-link>
                                                    </el-popconfirm>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" align="middle">
                                    <div class="preview-box question">
                                        <div class="empty" v-if="!form.trainingQuestionDtos.length">
                                            <p class="color-gary  font_s16"
                                               style="padding-top: 120px;text-align: center">
                                                内容预览
                                            </p>
                                        </div>
                                        <div v-else class="content">
                                            <div v-for="(vm,index) in form.trainingQuestionDtos" class="question_item"
                                                 :key="vm.id">
                                                <p class="type">{{ vm.questionType === '1' ? '单选题' : '多选题' }}
                                                    {{ index + 1 }}/{{ form.trainingQuestionDtos.length }}</p>
                                                <p class="mb5" v-if="vm.questionPhotoUrl">
                                                    <img :src="vm.questionPhotoUrl" style="width: 100px;"/>
                                                </p>
                                                <p class="title">1、{{ vm.questionTitle }}</p>
                                                <ul>
                                                    <li v-for="(vm1,index) in vm.questionInfo"
                                                        :key="vm1.id">
                                                    <span :class="{checked:vm1.checked}">  {{
                                                            letters[index]
                                                        }} {{ vm1.content }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </section>
                        <section>
                            <el-form-item label="最晚完成时间：" prop="hasLastTime">
                                <el-radio-group v-model="form.hasLastTime">
                                    <el-radio label="0">无截止时间</el-radio>
                                    <el-radio label="1">需要</el-radio>
                                </el-radio-group>
                                <el-date-picker class="ml10" v-if="form.hasLastTime==='1'" v-model='form.lastTime'
                                                :picker-options="pickerOptions"
                                                type="datetime"
                                                format="yyyy-MM-dd HH:mm"
                                                placeholder="请选择截止时间"/>
                                <span class="ml10" style="color: #606266" v-if="form.hasLastTime==='1'">前完成</span>
                            </el-form-item>
                            <el-form-item label="发布范围：" prop="rangeType" v-if="form.trainingType!=='3'">
                                <el-radio-group v-model="form.rangeType">
                                    <el-radio :label="0">
                                        按城市
                                        <SelectDialog v-if="form.rangeType===0"
                                                      title="城市"
                                                      :api="getCityData"
                                                      params="2"
                                                      @change="handleRangeChange"
                                                      :replace-field="{label:'name',value:'shortCode'}"
                                                      v-model="form.cityIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                    <el-radio :label="1">
                                        按运营商
                                        <SelectDialog v-if="form.rangeType===1"
                                                      title="运营商"
                                                      :api="getOperatorList"
                                                      @change="handleRangeChange"
                                                      v-model="form.operatorIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                    <el-radio :label="2">
                                        按供应商
                                        <SelectDialog v-if="form.rangeType===2"
                                                      title="供应商"
                                                      :api="getSupplierList"
                                                      @change="handleRangeChange"
                                                      v-model="form.supplierIds"
                                                      :isEdit="isEdit"/>
                                    </el-radio>
                                </el-radio-group>
                                <div>
                                    <SelectDialog display-mode="panel"
                                                  :api="getCityData"
                                                  v-if="form.rangeType===0&&form.cityIds.length!==0"
                                                  params="2"
                                                  title="城市"
                                                  @change="handleRangeChange"
                                                  :replace-field="{label:'name',value:'shortCode'}"
                                                  v-model="form.cityIds"
                                                  :isEdit="isEdit"
                                    />
                                    <SelectDialog display-mode="panel"
                                                  :api="getOperatorList"
                                                  v-if="form.rangeType===1&&form.operatorIds.length!==0"
                                                  title="运营商"
                                                  @change="handleRangeChange"
                                                  v-model="form.operatorIds"
                                                  :isEdit="isEdit"/>
                                    <SelectDialog display-mode="panel"
                                                  :api="getSupplierList"
                                                  v-if="form.rangeType===2&&form.supplierIds.length!==0"
                                                  title="供应商"
                                                  @change="handleRangeChange"
                                                  v-model="form.supplierIds"
                                                  :isEdit="isEdit"/>
                                </div>
                            </el-form-item>
                        </section>
                    </el-form>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="display: flex;justify-content: flex-end;width: 100%">
                    <el-popover placement="top" width="300" title="保存宣导" v-model="popover.visible1"
                                v-if="isEdit&&form.trainingType!=='3'">
                        <p>确认保存宣导？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button @click="popover.visible1 = false" style="width: 64px">取消</el-button>
                            <el-button type="primary" @click="handleSubmit(0)" :loading="loading"
                                       style="width: 64px;margin-left: 10px">确定
                            </el-button>
                        </div>
                        <el-button slot="reference" type="primary" style="margin-left: 20px" plain :loading="loading">
                            保存
                        </el-button>
                    </el-popover>
                    <el-popover placement="top" width="300" title="发布宣导" v-model="popover.visible3" v-if="isEdit">
                        <p>确认发布宣导？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button @click="popover.visible3 = false" style="width: 64px">取消</el-button>
                            <el-button type="primary" @click="handleSubmit(1)" :loading="loading"
                                       style="width: 64px;margin-left: 10px">确定
                            </el-button>
                        </div>
                        <el-button slot="reference" type="primary" style="margin-left: 20px" :loading="loading">
                            发布宣导
                        </el-button>
                    </el-popover>
                </div>
            </div>
        </el-footer>
        <el-dialog v-if="pageActivated" :visible.sync="dialogVisible" width="80%" :title="dialogType===1?'题库':'课件'"
                   top="30px">
            <CoursewareList ref="coursewareList" v-if="dialogType===0" :type="1"
                            :multipleSelection="multipleSelection"
                            @selection-change="handleSelectionChange"
                            @total-change="totalSelectItem=$event"></CoursewareList>
            <QuestionList ref="questionList" v-if="dialogType===1"
                          :type="1"
                          :multipleSelection="multipleSelection"
                          @selection-change="handleSelectionChange"
                          @total-change="totalSelectItem=$event"></QuestionList>
            <div slot="footer">
                <span class="font_s12 color-gary mr10">已选{{
                        multipleSelection.length
                    }}/{{ dialogType === 1 ? 100 : 3 }}</span>
                <el-button type="primary" plain @click="dialogVisible=false">取消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {trainingTypeOptions} from '@/data/driverTraining'
import SelectDialog from './SelectDialog'
import {getCityData, getOperatorList, getSupplierList} from '@/urls'
import dayjs from "dayjs";
import CoursewareList from "@/www/pages/driverManage/driverLearning/courseware/index.vue";
import QuestionList from "@/www/pages/driverManage/driverLearning/question/index.vue";
import {addTrainingApi, trainingListApi, updateTrainingApi, updateTrainingStatusApi} from "@/www/urls/driverLearning";

const createForm = () => ({
    trainingType: trainingTypeOptions[0].value,
    trainingName: '',
    trainingQuestionDtos: [],
    trainingLessonDtos: [],
    cityIds: [],
    supplierIds: [],
    operatorIds: [],
    lastTime: undefined,
    hasLastTime: '0',
    rangeType: 0,
    items: [],
})
export default {
    components: {
        SelectDialog,
        CoursewareList,
        QuestionList
    },
    data() {
        return {
            disabledTrainingType: false,
            letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
            dialogVisible: false,
            dialogType: 0,//0 课件 1 题库
            loading: false,
            isEdit: true,
            trainingTypeOptions,
            getCityData,
            getOperatorList,
            getSupplierList,
            form: createForm(),
            popover: {
                visible1: false,
                visible2: false,
                visible3: false,
            },
            rules: {
                trainingName: [
                    {required: true, message: '请输入标题，最多可填入15个中文字', trigger: 'blur'}
                ],
                trainingLessonDtos: [
                    {required: true, message: '请去课件中选择内容', trigger: 'blur'}
                ],
                trainingQuestionDtos: [
                    {required: true, message: '请去题库中选择题目', trigger: 'blur'}
                ],
                rangeType: [
                    {
                        required: true,
                        validator: (r, val, cb) => {
                            switch (val) {
                                case 0:
                                    if (this.form.cityIds.length === 0)
                                        return cb('请设置发布城市范围')
                                    break;
                                case 1:
                                    if (this.form.operatorIds.length === 0)
                                        return cb('请设置发布运营商范围')
                                    break;
                                case 2:
                                    if (this.form.supplierIds.length === 0)
                                        return cb('请设置发布供应商范围')
                                    break;
                            }
                            cb()
                        },
                        trigger: 'change'
                    },
                ],
                hasLastTime: [{
                    validator: (rule, value, cb) => {
                        if (value === '1' && !this.form.lastTime)
                            return cb('最晚完成时间')
                        cb();
                    },
                    trigger: 'blur'
                },]
            },
            multipleSelection: [],
            totalSelectItem: 0,
            pickerOptions: {
                disabledDate(date) {
                    return dayjs(date).isBefore(dayjs())
                }
            },
            hasPreJobTraining: true,
            pageActivated: false
        }
    },
    activated() {
        this.init();
    },
    methods: {
        init() {
            if (this.$route.query.t !== this._t) {
                this.form = createForm();
                this._t = this.$route.query.t
            }
            if (this.$route.query.form)
                this.getDetail(this.$route.query.form)
            this.isEdit = !this.$route.query.type;
            this.getPreJobTrainingList();
            this.$refs['form'].clearValidate();
            this.pageActivated = true
        },
        dayjs,
        async handleSubmit(trainingStatus) {
            this.loading = true
            try {
                await this.$refs['form'].validate()
                let ret;
                const name = this.form.items.map(({label}) => label).join(',')
                const form = {
                    ...this.form,
                    trainingLessonDtos: this.form.trainingLessonDtos.map(({id}) => ({
                        lessonId: id
                    })),
                    trainingQuestionDtos: this.form.trainingQuestionDtos.map(({id}) => ({
                        questionId: id
                    })),
                    lastTime: this.form.hasLastTime === '1' ? this.form.lastTime : '',
                    cityId: this.form.rangeType === 0 ? this.form.cityIds.join(',') : '',
                    cityName: this.form.rangeType === 0 ? name : '',
                    supplierId: this.form.rangeType === 2 ? this.form.supplierIds.join(',') : '',
                    supplierName: this.form.rangeType === 2 ? name : '',
                    operatorId: this.form.rangeType === 1 ? this.form.operatorIds.join(',') : '',
                    operatorName: this.form.rangeType === 1 ? name : '',
                    trainingStatus
                }
                if (this.form.id) {
                    ret = await updateTrainingApi(form)
                    if (trainingStatus === 1) {
                        await updateTrainingStatusApi({
                            id: this.form.id,
                            trainingStatus: 1
                        })
                    }
                } else
                    ret = await addTrainingApi(form)
                this.loading = false
                if (!ret.success)
                    return this.$message.error(ret.errors[0].message)
                this.$message.success(this.form.id ? '编辑成功~' : '新增成功~');
                this.$router.push(`/driverLearning?t=${+new Date()}`)
                this.form = createForm();
                this.$store.commit('delete_tabs', '/driverLearningAdd')
            } finally {
                this.popover.visible1 = false
                this.popover.visible3 = false
                this.loading = false
            }
        },
        handleRangeChange(val, items) {
            this.form.items = items;
            this.$refs['form'].validateField(['rangeType'])
        },
        async getPreJobTrainingList() {
            try {
                this.hasPreJobTraining = true;
                const ret = await trainingListApi({
                    trainingType: 3
                })
                if (ret.success && ret.data && ret.data.total === 0) {
                    this.hasPreJobTraining = false
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getDetail() {
            try {
                const form = JSON.parse(this.$route.query.form)
                this.form = {
                    id: form.id,
                    trainingType: form.trainingType + '',
                    trainingName: form.trainingName,
                    lastTime: form.lastTime,
                    hasLastTime: form.lastTime ? '1' : '0',
                    cityIds: form.cityId ? form.cityId.split(',') : [],
                    supplierIds: form.supplierId ? form.supplierId.split(',') : [],
                    operatorIds: form.operatorId ? form.operatorId.split(',') : [],
                    rangeType: form.cityId ? 0 : (form.supplierId ? 2 : 1),
                    trainingQuestionDtos: form.userQuestionDtos ? form.userQuestionDtos.map((item) => ({
                        ...item,
                        questionInfo: JSON.parse(item.questionInfo)
                    })) : [],
                    trainingLessonDtos: form.userLessonDtos,
                    items: ((form.cityName || form.supplierName || form.operatorName) + '').split(',').map((name) => ({
                        label: name
                    }))
                }
                this.disabledTrainingType = form.trainingType == 3
                console.log(this.form);
            } catch (err) {
                console.log(err);
            }
        },
        async handleCancel() {
            try {
                if (this.$route.query.type === '1' && this.isEdit)
                    await this.$confirm('请确认所有修改已保存。', '提示', {
                        type: 'warning'
                    })
                this.$router.push('/driverLearning')
                this.form = createForm();
            } catch (err) {
                console.log(err);
            }
        },
        showDialog(type) {
            this.dialogType = type;
            this.dialogVisible = true;
            let list = []
            if (type === 0) {
                list = [...this.form.trainingLessonDtos]
            } else {
                list = [...this.form.trainingQuestionDtos]
            }
            this.multipleSelection = list;
            this.totalSelectItem = list.length;
        },
        handleConfirm() {
            if (this.dialogType === 0)
                this.form.trainingLessonDtos = [...this.multipleSelection];
            else
                this.form.trainingQuestionDtos = [...this.multipleSelection]
            this.dialogVisible = false;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleDelItems(type, index) {
            if (type === 0) {
                this.form.trainingLessonDtos.splice(index, 1)
            } else this.form.trainingQuestionDtos.splice(index, 1)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-button {
        width: 120px;
        padding: 10px 0;
        font-weight: normal;
    }

    .el-radio__label, .el-checkbox__label {
        font-weight: normal;
    }

    .el-button--warning {
        background-color: #E6A233;
        border-color: #E6A233;
    }

    .el-button--default {
        border: 1px solid #1989FA;
        border-radius: 4px;
        color: #1989FA;
    }
}

.container {
    position: relative;

    .mask {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 9999;
    }
}

section {
    padding: 20px;
    margin-top: 10px;
    background-color: #fff;
}

.eTrip-footer-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.preview-box {
    height: 300px;
    width: 300px;
    box-sizing: border-box;
    position: relative;
    color: #333333;
    text-align: left;
    margin-top: 50px;

    .empty {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: #F2F2F2;
    }

    .content {
        border: 1px solid rgba(220, 223, 230, 1);
        padding: 0 12px;
        height: 300px;
        width: 300px;
        overflow: hidden;
        overflow-y: scroll;
        border-radius: 4px;
    }

    &.lesson {
        .content {
            background: #F9F9F9;

            img {
                width: 12px;
                margin-right: 5px;
            }

            font-size: 10px;

            .hd {
                background-image: linear-gradient(100deg, #F1F7FF 3%, #E7F1FE 100%);
                margin-left: -12px;
                margin-right: -12px;
                padding: 10px 12px;

                .title {
                    font-size: 14px;
                    color: #333333;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
            }

            & > .title {
                padding: 10px 0;
            }

            ul {
                li {
                    display: flex;
                    margin-bottom: 10px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    padding: 10px;

                    .cover {
                        border-radius: 6px;
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &.question {

        font-size: 10px;
        background-color: #fff;

        .question_item {
            padding: 10px 0;
            position: relative;
        }

        .question_item:not(:last-child)::after {
            position: absolute;
            content: ' ';
            left: 0;
            right: 0;
            background-color: rgba(220, 223, 230, 1);
            height: 1px;
        }

        .type {
            font-size: 9px;
            margin-bottom: 7px;
        }

        .title {
            font-weight: 500;
            margin-bottom: 12px;
        }

        li {
            margin-bottom: 10px;

            span {
                padding: 1px 7px;

                &.checked {
                    background: #0452DD;
                    border-radius: 8.34px;
                    color: #fff;
                }
            }

        }
    }
}

.topic-table {
    margin-top: 15px;
}

::v-deep(.topic-table) {
    table, .el-table__empty-block {
        width: 100% !important;
    }
}

</style>
